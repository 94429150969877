import Api from '@/utils/request'
import CustomSelect from "@/pages/components/CustomSelect"
import CustomButton from "@/pages/components/CustomButton"
import CustomCheck from "@/pages/components/CustomCheck"
export default {
  components:{ CustomSelect, CustomButton, CustomCheck },
  filters: {
    toCompetitorStatusLabel(key) {
      return ({ winner: 'Kazanan', mention: 'Mansiyon' })[key]
    }
  },
  data() {
    return {
      dragAndDropArea: true,
      buttonText: 'Kaydet & Yarışmaya Katıl',
      initialURL: '',
      selectedCheckBox: false,
      file: {
        stamp: null
      },
      uploading: false,
      tempIcons: [],
      fileNames: [],
      contest: {
        id: null,
        imageUrl: null,
        title: null,
        description: null,
        ruleTitle: null,
        ruleDescription: null,
        competitors: [],
        isAppliable: false,
        endedAt: null
      },
      buttonLoading: false,
      isApplied: false
    }
  },
  methods: {
    apply() {
      this.buttonLoading = true
      const form = {
        filename: this.file.filename,
        url: this.file.url,
        icon_png: this.file.icon_png
      }
      Api.post('/contests/efsaneyarisma/apply', form)
        .then(() => {
          this.buttonText = 'Değişikliği Kaydet'
          this.file = {
            url: this.file.url,
            filename: this.file.filename,
            icon_png: this.file.icon_png
          }
          this.initialURL = this.file.url
          this.$toasted.global.infoToast({description: "İşlem tamam!"})
          this.isApplied = true
          this.buttonLoading = false
        })
        .catch(err => {
          if ([403, 404].includes(err.status)) {
            this.$toasted.global.errorToast({ description: err.data.message })
          } else {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
          }
        })
        .finally(() => {
          this.buttonLoading = false
        })
    },
    getContest() {
      this.buttonLoading = true
      return Api.get('/contests/efsaneyarisma')
        .then(({ data }) => {
          this.contest.id = data.id
          this.contest.imageUrl = this.$store.state.isMobile ? data.bannerMobileImageUrl : data.bannerImageUrl
          this.contest.title = data.title
          this.contest.description = data.description
          this.contest.ruleTitle = data.ruleTitle
          this.contest.ruleDescription = data.ruleDescription
          this.contest.competitors = data.competitors
          this.contest.isAppliable = data.isAppliable
          this.contest.endedAt = data.endedAt
          this.$store.state.indexHTMLTitle = data.title + ' | Bionluk'
          this.$store.state.indexHTMLMetaDescription = data.title
          if (data.applierInfo) {
            this.buttonText = 'Değişikliği Kaydet'
            this.file = {
              url: data.applierInfo.url,
              filename: data.applierInfo.filename,
              icon_png: data.applierInfo.icon_png
            }
            this.initialURL = data.applierInfo.url
          }
        })
        .catch(err => {
          if ([403, 404].includes(err.status)) {
            this.$toasted.global.errorToast({ description: err.data.message })
          } else {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
          }
        })
        .finally(() => {
          this.buttonLoading = false
        })
    },
    showModal() {
      let modalInfo = {
        title: this.contest.ruleTitle,
        bodyText: this.contest.ruleDescription,
        actionButton:{
          show: true,
          title: "Okudum ve Anladım"
        }
      }
      this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.WARNING_MODAL, info: modalInfo})
    },
    reUpload(){
      this.file = {
        stamp: null
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return

      let timeStamp = +new Date()
      this.uploading = true

      for (let i = 0; i < files.length; i++) {
        let rand = Math.floor(Math.random() * 1000) + 1
        timeStamp = timeStamp + rand + i
        this.file = {
          url: null,
          externalID: timeStamp,
          filename: files[i].name,
          stamp: timeStamp,
          filesize:files[i].size
        }

        this.uploadFileToGoogle(files[i], 'blog',null, timeStamp)
          .then(result => {
            this.file.externalID = result.externalID
            this.file.url = this.Constants.CDN_UPLOADS_G + result.uploadName
            this.file.icon_png = result.icon_png
            this.uploading = false
            this.$toasted.global.infoToast({description: 'Tasarım başarıyla yüklendi. Değişikliklerin geçerli olması için formu kaydetmelisin.'})
          })
          .catch(err => {
            this.$store.state.percentCompleted.blog[timeStamp] = 0
            this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
            this.uploading = false
          })
      }

    },
    chooseFiles(id) {
      document.getElementById(id).click()
    }
  },
  computed: {
    isValid() {
      return this.selectedCheckBox && this.file && this.file.url
    }
  },
  watch:{
    'percentCompleted.temp_icon_png': function (newValue, oldValue) {
      this.tempIcons = newValue;
    },
    'percentCompleted.fileNames': function (newValue, oldValue) {
      this.fileNames = newValue;
    },
  },
  created() {
    this.getContest()
  }
}
