<template>
  <div class="levels-body">
    <div class="levels-row-body">
      <div class="levels-row-main">
        <img style="width: 100%; border-top-left-radius: 10px; border-top-right-radius: 10px;" :src="contest.imageUrl" />
        <div style="padding: 49px 50px 73px; line-height: 1.64; color: #2d3640; font-size: 22px;">
          <p v-html="contest.description" />
          <p style="margin-top: 10px;">Çalışmanı tamamladıktan sonra; özgün tasarımını yüklemen yeterli! Yarışmaya katılmadan önce <span @click="showModal()" style="cursor: pointer; text-decoration: underline; font-weight: 500; color: #00a575;">yarışma kurallarına</span> göz atmayı unutma!</p>
          <p v-if="!contest.isAppliable" style="margin-top: 50px; font-weight: 600; margin-bottom: 0px">Yarışma sonuçlandı, tüm katılan adaylara teşekkürler. ☺️</p>
        </div>
        <div v-if="!contest.isAppliable && contest.competitors.length" class="section-body">
          <div class="contact-info">
            <div v-for="(competitor, index) in contest.competitors" :key="index">
              <div v-if="index !== 0" class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
              <div class="contact-item">
                <label class="contact-label lefti" style="color: #2D3640; font-weight: 600;">{{ competitor.status | toCompetitorStatusLabel }}</label>
                <p class="contact-text lefti">
                  <router-link style="color: #fa4359; font-weight: 500" :to="`/${competitor.user.username}`">{{ competitor.user.username }}</router-link>
                </p>
                <p class="contact-text lefti">
                  {{ competitor.info.reward }}
                </p>
                <p class="contact-text lefti">
                  <a :href="competitor.info.url" target="_blank">
                    <img style="width: 100px;" :src="competitor.info.url">
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="contest.isAppliable" class="section-body">
          <div class="contact-info">
            <div class="contact-item">
              <label class="contact-label left">Giriş yaptığın kullanıcı hesabı:</label>
              <p class="contact-text right">
                <span v-if="user.username" style="font-weight: 600; font-size: 18px;">{{ user.username }}</span>
                <span v-else>Yarışmaya katılabilmek için <router-link to="/login" style="color: #00a575; font-weight: 500">üye girişi</router-link> yapman gerekiyor.</span>
              </p>
            </div>
            <div v-if="user.username">
              <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
              <div class="contact-item">
                <label class="contact-label left">Yüklediğin Tasarım:</label>
                <div>
                  <template v-if="file.stamp && $store.state.percentCompleted.blog[file.stamp]">
                    <loader v-if="$store.state.percentCompleted.blog[file.stamp] < 0.01 && $store.state.percentCompleted.blog[file.stamp] > 0"></loader>
                    <div class="uploading-div" v-if=" $store.state.percentCompleted.blog[file.stamp] > 0.01">
                      <img :src="tempIcons[file.stamp]" style="width: 39px; height: 50px;">
                      <div style="margin-left:15px; display: flex; align-items: flex-end">
                        <div>
                          <p class="file-name-p" >{{ fileNames[file.stamp] }}</p>
                          <progress style="margin-top:10px; height: 12px; border-radius: 7px; margin-left: 0; width: 310px" class="progressbar-purple" :value='$store.state.percentCompleted.blog[file.stamp]' max="100"></progress>
                        </div>
                        <div>
                          <a style="margin-left: 20px; margin-top: 20px; " @click="cancelUploadRequest(parseInt(file.stamp))">
                            <img src="https://gcdn.bionluk.com/site/icon/tansel_chat_attachmentcancel.svg" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div v-if="file.url">
                    <p v-if="isApplied" style="color: #00a575; font-weight:500;margin-bottom: 30px; margin-top: 10px">Yarışmaya katılımın başarıyla kaydedildi. {{ contest.endedAt }} tarihine kadar kazananı bildireceğiz. ☺️</p>
                    <div class="uploading-div">
                      <img :src="file.icon_png" style="width: 39px; height: 50px;">
                      <div style="margin-left:15px; display: flex; align-items: flex-end">
                        <div>
                          <a :href="file.url" target="_blank" style="font-weight: 500; font-size: 16px" class="file-name-p" >{{ file.filename }} </a>
                        </div>
                      </div>
                    </div>
                    <custom-button @click="reUpload()" :button-style="'padding-bottom:2px;width:200px; height:43px; font-weight:400; font-size:14px;'">Başka Tasarım Yükle</custom-button>
                  </div>
                  <div v-else-if="!uploading" class="upload-area-container">
                    <div v-if="dragAndDropArea" class="upload-area" >
                      <p class="upload-title">Özgün Tasarım Yükle</p>
                      <p class="upload-sub-title" style="margin-top: 20px;">Freelancer temalı özgün çalışmanı bu alana yükleyebilirsin.</p>
                      <div style="margin: 50px auto 0;width: 220px;">
                        <button @click="chooseFiles('request_upload')" style="width: 220px; height: 54px;" class="cuper-black-button">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-round-plus.svg"  onload="SVGInject(this)" class="upload-plus-icon">
                          <span style="padding-left: 14px;">Dosya Ekle</span>
                        </button>
                      </div>
                      <p class="upload-foot-title">Dikkat etmen gerekenler:</p>
                      <p class="upload-foot-sub-title">JPG, PNG dosyaları yükleyebilirsin. Ayrıca tasarım <strong style="color: #2D3640">1080x1250px</strong> boyutlarında ve 70dpi olmalı </p>
                    </div>
                  </div>
                </div>
              </div>
              <input id="request_upload" style="visibility: hidden" type="file" @change="onFileChange($event)" accept=".jpg, .jpeg, .png">
              <div v-if="!uploading && (!initialURL || initialURL !== file.url)" style="display: flex; align-items: center; height: 20px; margin-bottom: 40px; margin-top: 30px; ">
                <CustomCheck
                  :single-gig="{}"
                  :id="'dsadsa'"
                  :checked="selectedCheckBox"
                  :value="selectedCheckBox"
                  @input="selectedCheckBox = !selectedCheckBox"
                />
                <p style="margin-left: 10px">Yarışma <span @click="showModal()" style="color: #2D3640; text-decoration: underline; font-weight: 500; cursor:pointer;">katılım şartlarını ve kurallarını</span> okudum ve kabul ediyorum.
                </p>
              </div>
              <div class="separator" style="margin-bottom: 17px;"></div>
              <div v-if="!uploading && (!initialURL || initialURL !== file.url)" style="display: flex; flex-direction: column; align-items:center;justify-content: center; margin-top: 40px; margin-bottom: 40px;">
                <CustomButton v-if="!buttonLoading" @click="apply" :disabled="!isValid" button-type="red">{{ buttonText }}</CustomButton>
                <loader v-else style="width: 30px; height: 30px"/>
                <p style="margin-top: 30px" v-if="!user.username">Yarışmaya katılabilmek için <router-link to="/login" style="color: #00a575; font-weight: 500">üye girişi</router-link> yapman gerekiyor.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin]
}

</script>

<style scoped lang="scss">



.uploading-div{
  width: 430px;
  margin: 0 0 20px;
  padding: 25px 20px 25px 25px;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border: solid 1px rgba(43, 181, 215, 0.2);
  background-color: rgba(101, 196, 219, 0.2);

  display: flex;
  align-items: center;

  .file-name-p{

    line-height: 1.29;
    letter-spacing: -0.5px;
    color: #2d3640;
    width: 270px;
    font-size: 14px;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden; white-space: pre;


  }
}


.modal-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, .8);
  .modal-container{
    font-family: 'sofia-pro';
    box-sizing: border-box;
    width: calc(100vw - 20px) !important;

    max-width: 770px;

    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    position: relative;

  }
  .close-div {
    z-index: 1;
    top: 20px;
    right: 20px;
    opacity: 0.75;
    position: absolute;
  }

  .close-div:hover {
    opacity: 1;
  }

  .modal-inner {
    overflow-y: scroll;
    max-height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 30px;
    color: #2d3640;

    span {
      font-weight: 600;
    }
  }
}

.filter-checkbox-item{
  cursor: pointer;
  display: flex;
  width: 50%;
  margin-bottom: 30px;


  .filter-checkbox-disabled{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px #8b95a1;
    opacity: 0.4;
  }

  .filter-checkbox-label-disabled{
    padding-left: 10px;
    font-size: 15px;
    opacity: 0.55;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #2D3640;

  }

  .filter-checkbox-empty{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px #8b95a1;
  }

  .filter-checkbox-full{
    width: 12px;
    height: 12px;
    border-radius: 5px;
    border: solid 3px #00a575;

  }

  .filter-checkbox-label{
    padding-left: 10px;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #5e6b79;

  }

  .filter-checkbox-label-active{
    padding-left: 10px;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #5e6b79;

  }

}



.upload-plus-icon /deep/ {
  width: 18px;
  height: 18px;
  path {
    fill: #fff;
    &:hover{
      opacity: 0.8;
    }
  }


}

.levels-body {

}

.levels-header {
  padding: 35px 0;
  h1 {
    font-size: 34px;
    font-weight: 800;
    color: rgb(45, 54, 64);
    text-align: center;
  }
}

.levels-row-body {
  display: flex;
  flex-direction: column;
}

.levels-row-main {

  margin-top: 80px;
  margin-bottom: 80px;
  border-radius: 10px;
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #e5e5e5;
  box-shadow: 5px 0 20px 0 rgba(45, 54, 64, 0.2);
  background-color: #ffffff;
}


.section-body {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #eaedf2;
  background-color: #fff;
  min-width: 100%;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 34px;
  padding-bottom: 34px;
}

.contact-info {
  display: flex;
  flex-direction: column;

}

.contact-item {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;

  .left {
    width: 25%;
    align-self: flex-start;
  }
  .lefti {
    width: 25%;
    align-self: center;
  }

  .right {
    width: 75%;
  }
}

.contact-label {
  font-size: 16px;
  color: #8b95a1;
  font-weight: 400;
  margin: 0;
}

.contact-text {
  font-size: 16px;
  color: #2d3640;
  font-weight: 400;
  min-width: 173px;
}

.separator {
  border-bottom: 1px solid #eaedf2;
  width: 100%;
}


.upload-area-container{
  margin-top: 33px;
  display: flex;
  justify-content: space-between;
}

.upload-area-files{
  width: 393px;
  margin-left: 57px;

  .upload-files-title{
    font-size: 18px;
    font-weight: 600;
    color: #5e6b79;
  }
  .upload-file-count{
    font-size: 16px;
    font-weight: 500;
    color: #5e6b79;
  }

}
.upload-area{

  text-align: center;
  width: 393px;
  height: 380px;
  border-radius: 10px;
  border: dashed 2px #bfc8d2;
  background-color: rgba(191, 200, 210, 0.1);

  .upload-title{
    margin-top: 50px;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.08;
    color: #5e6b79;
  }
  .upload-sub-title{
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: -0.23px;
    color: #5e6b79;
  }
  .upload-or{
    margin-top: 40px;
    font-size: 14px;
    color: #8b95a1;
  }
  .upload-foot-title{
    margin-top: 64px;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
  }
  .upload-foot-sub-title{

    max-width: 85%;
    margin: 10px auto 0;
    font-size: 12px;
    font-weight: 300;
    color: #8b95a1;
    line-height: 1.4;
  }
}


</style>
